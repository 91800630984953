import * as React from "react";
import Contact from "../components/contact";
import Layout from "../components/layout";
import {
  CheckBox,
  PrimaryButton,
  PrimaryButton2,
  SecondaryButton,
  Slider,
  Container,
} from "../styles/ui";
import { StaticImage } from "gatsby-plugin-image";
import BizzMegMinket from "../components/marketing/bizzMegMinket";
import EgyuttmukodesiFormak from "../components/marketing/egyuttmukodesiFormak";

export const Marketing: React.FC = () => {
  return (
    <Layout allowTransparentHeader={true} variant="marketing">
      <main>
        {/* <Hero /> */}
        <BizzMegMinket />
        <EgyuttmukodesiFormak />
        <Container>
          <div style={{ padding: "10px" }}>
            <PrimaryButton>kattints</PrimaryButton>
          </div>
          <div style={{ padding: "10px" }}>
            <PrimaryButton2>kattints</PrimaryButton2>
          </div>
          <div style={{ padding: "10px" }}>
            <SecondaryButton>kattints</SecondaryButton>
          </div>
          <CheckBox />
          <div style={{ padding: "10px" }}>
            <Slider percent={20} />
          </div>
        </Container>
        {/* <div style={{ width: "100%" }}> */}
        <StaticImage
          src="../images/group1.jpg"
          alt="Csapat"
          objectFit="contain"
          layout="fullWidth"
        />
        {/* </div> */}
        <Contact variant="marketing" />
      </main>
    </Layout>
  );
};

export default Marketing;
